import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { defaultUser, LocalsContext, UserContext } from '../../App';
import Card from '../../components/Card/Card';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import { URL, USER_LOCAL_STORAGE_KEY } from '../../config';
import { CenterProps, Order } from '../../types';
import './Main.css';

function Main() {
  const mounted = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const { localId, accessToken, setUser } = useContext(UserContext);
  const { locals } = useContext(LocalsContext);
  const local = useMemo(() => {
    const numberLocalId = Number(localId);
    return locals.find((local) => local.id === numberLocalId);
  }, [localId, locals]);
  const [center, setCenter] = useState<CenterProps>({
    lat: -34.603839,
    lng: -58.380471,
  });
  const [orders, setOrders] = useState<Order[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  useEffect(() => {
    mounted.current = true;
    setIsLoading(true);
    const fetchOrdersAPI = async () => {
      try {
        const result = await fetch(`${URL}/autopickup-orders`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await result.json();
        if (mounted.current && result.ok) {
          setOrders(data as Order[]);
        } else if (mounted.current && result.status === 401) {
          localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
          setUser(defaultUser);
          setOrders([]);
        } else {
          console.log(result.status);
          console.log(data);
          setOrders([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setOrders([]);
        setIsLoading(false);
      }
    };

    fetchOrdersAPI();
  }, [accessToken, setUser]);

  useEffect(() => {
    local?.lat && local?.lng && setCenter({ lat: local.lat, lng: local.lng });
  }, [local]);

  const setOrderAsSelected = (orderId: string) => {
    const order = orders.find((order) => order.id === orderId);
    if (!order) return;
    if (order.lat && order.lng) {
      setCenter({
        lat: order.lat,
        lng: order.lng,
      });
      if (selectedOrder && selectedOrder.id === order.id) {
        setSelectedOrder(null);
      } else {
        setSelectedOrder(order);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <header className='main-header'>
            <p className='local-name'>{local && local.name}</p>
            <p className='local-address'>{local && local.address}</p>
          </header>
          <GoogleMap local={local} order={selectedOrder} center={center} />
          <div className='cards-container'>
            {orders.map((order) => (
              <Card
                key={order.id}
                id={order.id}
                turno={order.turno}
                clientName={order.clientName}
                isSelected={selectedOrder ? order.id === selectedOrder.id : false}
                telephone={order.telephone}
                state={order.state}
                colorAuto={order.colorAuto}
                modeloAuto={order.modeloAuto}
                patenteAuto={order.patenteAuto}
                setSelectedOrder={setOrderAsSelected}
              />
            ))}
          </div>{' '}
        </>
      )}
    </>
  );
}

export default Main;
