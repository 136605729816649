import { FC } from 'react';
import GoogleMapReact from 'google-map-react';
import './styles.css';
import { GOOGLE_MAP_KEY } from '../../config';
import { CenterProps, Local, Order } from '../../types';
import { ReactComponent as MostazaMarker } from '../../assets/mostaza-marker.svg';
import { ReactComponent as OrderMarker } from '../../assets/order-marker.svg';

//Type Definition
interface MapProps {
  center?: CenterProps;
  defaultCenter?: CenterProps;
  zoom?: number;
  defaultZoom?: number;
  options?: GoogleMapReact.MapOptions | ((maps: GoogleMapReact.Maps) => GoogleMapReact.MapOptions);
  local?: Local;
  order: Order | null;
}

interface LocalMarkerProps {
  text: string;
  lat: number;
  lng: number;
  id: number;
}

interface OrderMarkerProps {
  clientName: string;
  lat: number;
  lng: number;
  id: string;
}

//Map Marker Component
const LocalMarkerComponent: FC<LocalMarkerProps> = ({ text, lat, lng, id }) => (
  <MostazaMarker className='map-marker' />
  // <div
  //   className={`map-marker ${isSelected ? "selected-marker" : ""}`}
  //   className={`map-marker`}
  //   onClick={() => setSelectedRestaurent(id)}
  // >
  //   <i className="fas fa-map-marker-alt tooltip">
  //     {isSelected && <span className="tooltiptext">{text}</span>}
  //   </i>
  // </div>
);
const OrderMarkerComponent: FC<OrderMarkerProps> = ({ clientName, lat, lng, id }) => <OrderMarker className='client-marker' />;

const GoogleMap: FC<MapProps> = ({ center, defaultCenter, zoom, defaultZoom, options, local, order }) => {
  return (
    <div className='map-container'>
      <GoogleMapReact bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }} defaultCenter={defaultCenter} center={center} options={options} zoom={zoom} defaultZoom={defaultZoom}>
        {local && <LocalMarkerComponent lat={local.lat} lng={local.lng} text={local.address} id={local.id} />}
        {order && <OrderMarkerComponent lat={order.lat} lng={order.lng} clientName={order.clientName} id={order.id} />}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;

GoogleMap.defaultProps = {
  defaultCenter: {
    lat: -34.603839,
    lng: -58.380471,
  },
  center: {
    lat: -34.603839,
    lng: -58.380471,
  },
  options: {
    disableDefaultUI: true,
  },
  defaultZoom: 14,
  zoom: 14,
};
