import { FC, useCallback, useMemo } from 'react';
import './styles.css';

interface CardProps {
  id: string;
  turno: string;
  clientName: string;
  telephone: string;
  state: string;
  colorAuto: string;
  modeloAuto: string;
  patenteAuto: string;
  isSelected: boolean;
  setSelectedOrder: (orderId: string) => void;
}

const Card: FC<CardProps> = ({ id, isSelected, turno, clientName, telephone, state, colorAuto, modeloAuto, patenteAuto, setSelectedOrder }) => {
  const hasClick = useMemo(() => state === 'CONFIRMADO_POR_CLIENTE', [state]);

  const clickFunction = useCallback(() => {
    if (!hasClick) return;

    setSelectedOrder(id);
  }, [hasClick, setSelectedOrder, id]);

  return (
    <div className={`card-container${hasClick ? ' confirmed' : ''}${isSelected ? ' selected' : ''}`} onClick={clickFunction}>
      <div>
        <h4>
          Nº DE ORDEN: <span>{turno}</span>
        </h4>
        <p>{clientName}</p>
        <p>{telephone}</p>
        <p>
          {modeloAuto} / {patenteAuto} / {colorAuto}
        </p>
      </div>
      <h5>{state === 'CONFIRMADO' ? 'EN CURSO' : hasClick ? 'CONFIRMADO' : ''}</h5>
    </div>
  );
};

export default Card;
