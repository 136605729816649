import { useContext, useState, useReducer, useRef, useEffect, useCallback } from 'react';
import logo from '../../assets/logo-mostaza.svg';
import { Result } from '../../types';
import { URL, USER_LOCAL_STORAGE_KEY } from '../../config';

import './Login.css';
import { LocalsContext, UserContext } from '../../App';

const formReducer = (state: any, event: any) => {
  if (event.reset) {
    return {
      local: '',
      password: '',
      save: false,
    };
  }
  return {
    ...state,
    [event.name]: event.value,
  };
};

function Login() {
  const { setUser } = useContext(UserContext);
  const { locals } = useContext(LocalsContext);
  const mounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useReducer(formReducer, { save: false });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      if (!formData.local || !formData.password) {
        setIsLoading(false);
        alert('Por favor, completar todos los campos');
        return;
      }
      try {
        const dataJson = {
          username: formData.local,
          password: formData.password,
        };
        const result = await fetch(`${URL}/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataJson),
        });
        const data = (await result.json()) as Result;
        if (result.ok) {
          localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(data));
          setUser(data);
        } else {
          if (data.error) {
            alert(data.error);
          }
        }
      } catch (error) {
        console.error(error);
      }
      if (mounted.current) {
        setIsLoading(false);
        setFormData({
          reset: true,
        });
      }
    },
    [formData, setUser]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData({
      name: event.target.name,
      value: isCheckbox ? event.target.checked : event.target.value,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  return (
    <>
      <img src={logo} className='logo' alt='Logo Mostaza' />
      <form className='login-form' onSubmit={handleSubmit}>
        <fieldset className='username-fieldset' disabled={isLoading}>
          <select aria-label='Tienda' name='local' id='local-selector' onChange={handleSelectChange} value={formData.local || ''} className='select'>
            <option disabled hidden value=''>
              Tienda
            </option>
            {locals.map((local) => (
              <option key={local.id} value={local.id}>
                {local.name}
              </option>
            ))}
          </select>
        </fieldset>
        <fieldset className='password-fieldset' disabled={isLoading}>
          <input type='password' required name='password' placeholder='Contraseña' onChange={handleChange} value={formData.password || ''} />
        </fieldset>
        <fieldset className='checkbox-fieldset' disabled={isLoading}>
          <label htmlFor='local-checkbox'>
            <input type='checkbox' name='save' id='local-checkbox' checked={formData['save'] || false} onChange={handleChange} /> Guardar Contraseña
          </label>
        </fieldset>
        <input type='submit' disabled={isLoading} value='Entrar' />
      </form>
    </>
  );
}

export default Login;
