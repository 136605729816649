import { createContext, useState, useEffect, useRef } from 'react';
import './App.css';
import { Local, Result } from './types';
import Login from './views/Login/Login';
import Main from './views/Main/Main';
import { URL, USER_LOCAL_STORAGE_KEY } from './config';

interface User {
  accessToken: string;
  name: string;
  id: string;
  username: string;
  localId: string;
}

interface UserContextProps {
  accessToken: string;
  name: string;
  id: string;
  username: string;
  localId: string;
  setUser: (user: User) => void;
}

interface LocalsContextProps {
  locals: Local[];
  setLocals: (locals: Local[]) => void;
}

export const defaultUser = {
  accessToken: '',
  name: '',
  id: '',
  username: '',
  localId: '',
};

export const UserContext = createContext<UserContextProps>({
  ...defaultUser,
  setUser: () => {},
});

export const LocalsContext = createContext<LocalsContextProps>({
  locals: [],
  setLocals: () => {},
});

function App() {
  const [loading, setIsLoading] = useState<boolean>(true);
  const mounted = useRef(false);
  const [locals, setLocals] = useState<Local[]>([]);
  const [user, setUser] = useState<User>(defaultUser);

  useEffect(() => {
    mounted.current = true;
    setIsLoading(true);
    const fetchLocalsAPI = async () => {
      try {
        const result = await fetch(`${URL}/locals/autopickup`);
        const data = (await result.json()) as Local[];
        if (mounted.current && result.ok) {
          const sortedLocals = data.sort((a, b) => a.name.localeCompare(b.name));
          setLocals(sortedLocals);
        }
      } catch (error) {
        console.error(error);
        setLocals([]);
      }
    };

    fetchLocalsAPI();
    const mostazaUserString = localStorage.getItem(USER_LOCAL_STORAGE_KEY);

    if (!mostazaUserString) {
      if (mounted.current) {
        setIsLoading(false);
      }
      return;
    }

    const mostazaUser: Result = JSON.parse(mostazaUserString);

    if (mounted.current) {
      setUser({ ...defaultUser, ...mostazaUser });
      setIsLoading(false);
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...user,
        setUser,
      }}>
      <LocalsContext.Provider value={{ locals, setLocals }}>
        <main className='app'>{loading ? <div>Cargando...</div> : user.accessToken ? <Main /> : <Login />}</main>
      </LocalsContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
